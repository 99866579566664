<template>
  <div class="customerManagement-view">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="first">
        <allClients v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="我的客户" name="second">
        <exclusiveClients v-if="activeName == 'second'" />
      </el-tab-pane>
      <el-tab-pane label="成单客户" name="third">
        <singleCustomer v-if="activeName == 'third'" />
      </el-tab-pane>
      <el-tab-pane label="公海客户" name="fourthly">
        <sharedClient v-if="activeName == 'fourthly'" />
      </el-tab-pane>
      
    </el-tabs>
  </div>
</template>

<script>
import exclusiveClients from "./components/totalCustomer/exclusiveClients";
import singleCustomer from "./components/totalCustomer/singleCustomer";
import sharedClient from "./components/totalCustomer/sharedClient";
import allClients from "./components/totalCustomer/allClients";

export default {
  name: "customer-management",
  components: {
    exclusiveClients,
    singleCustomer,
    sharedClient,
    allClients
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>